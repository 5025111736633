@use "../../styles/constants.module" as styles;

.DropDownMenu .MuiMenuItem-root:hover,
.DropDownMenu .Mui-selected {   
    color: styles.$base-color !important;
    background-color: styles.$base-light-color !important
}

.DropDownMenu .MuiMenuItem-root {    
    width: 102px;
    min-width: 102px;
    font-size: 14px !important;
    font-family: 'Poppins' !important;
    border-radius: 3px !important;
    font-weight: 400 !important;
    margin: auto !important;
    padding: 3px 5px !important;
}