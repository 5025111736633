.filter {
  display: flex;
  justify-content: space-between;
  font-size: 15px;
  margin-bottom: 12.97px;  
}

.sortBy {
  display: flex;
  align-items: center;
}

.table {
  display: flex;
  flex: 1;
}

.virtualScrollerRenderZone {
  position: unset !important;
}

.virtualScrollerContent {
  height: fit-content !important;
  min-height: fit-content !important;
}

.virtualScrollerContentEmpty {
  height: 50px !important;
  min-height: auto !important;
}