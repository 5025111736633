@use "../../styles/constants.module" as styles;

$data-grid-root: ".MuiDataGrid-root";

.data-table {
    margin-top: 11px;
    display: flex;
    flex-direction: column;
    flex: 1;

    .data-table-header-cell .MuiDataGrid-columnSeparator--sideRight {
        display: none !important;
    }
    
    .data-table-header-cell {
        padding: 0px 11px  !important;
        font-weight: 400 !important;
        font-size: 15px !important;
        line-height: 22px !important;
        height: 43px !important;
        color: styles.$disabled-color;
    }
    
    .MuiDataGrid-footerContainer,
    #{$data-grid-root},
    #{$data-grid-root} .MuiDataGrid-cell {
        border: none;
    }

    #{$data-grid-root} .MuiDataGrid-row:hover, #{$data-grid-root} .MuiDataGrid-row.Mui-hovered {
        background: styles.$base-light-color;
        color: styles.$base-color;
        border-radius: 3px;
    }

    #{$data-grid-root} .MuiDataGrid-row {
        max-height: 43px !important;
        min-height: 43px !important;

        .MuiDataGrid-cell {            
            max-height: 43px !important;
            min-height: 43px !important;
        }
    }
    
    .MuiDataGrid-columnHeaders {
        border: none;
    }

    .MuiButtonBase-root.MuiPaginationItem-root.Mui-selected {
        color: styles.$base-color;
        background-color: styles.$base-light-color;
    }
}

.data-table-border {
    #{$data-grid-root} {
        border: 1px solid styles.$base-color;
        border-radius: 6px;
        padding-left: 20px;
        padding-right: 20px;
    }
}
    
.data-table-top-header-border {
    .MuiDataGrid-columnHeaders {
        border-top: 1px solid styles.$disabled-color;
        border-bottom: none;
        border-radius: 0px;
    }
}

.data-table-bottom-header-border {
    .MuiDataGrid-columnHeaders {
        border-bottom: 1px solid styles.$disabled-color;
        border-top: none;
        border-radius: 0px;
    }
}

.table-high-row {
    #{$data-grid-root} .MuiDataGrid-row {
        max-height: 65px !important;
        min-height: 65px !important;

        .MuiDataGrid-cell {
            max-height: 65px !important;
            min-height: 65px !important;
        }
    }
}