@use "../../../styles/constants.module.scss" as styles;

.title {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    padding-bottom: 14px;
}

.content {
    margin-bottom: 25px;
}

.actionArea {
    display: flex;
    flex-direction: row;
    width: 80px;
    height: 19px;
    background-color: styles.$base-color;
    padding: 2px;
    border-radius: 3px;    
    align-items: center;
    justify-content: space-around;
    button {
        padding: 0px;
        color: white;
        height: 15px;
        min-width: 15px;
    }
    .plus, .minus {
        background-color: #50ACF1;
    }
}