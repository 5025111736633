.root {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.title {
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 12.97px;
}

.ButtonArea {
    margin-top: 25px;
    display: flex;
    align-self: flex-end;
}

.ButtonArea Button:not(:last-child) {
    margin-right: 18px;
}

.FieldsArea {
    margin-top: 33px;
    display: flex;
    flex-direction: column;
}

.uploadWripper {
    height: 187px;
}

.notificationAreaWrapper {
    margin: auto;
    margin-top: 55px;
    .notificationArea {
        width: fit-content;
        display: flex;
        flex-direction: column;
        .notificationItem {
            display: flex;
            align-items: center;
            margin-bottom: 15px;
            font-size: 20px;
            img {
                width: 28px;
                margin-right: 10px;
            }
        }
    }  
}