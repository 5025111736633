@use "../../../styles/constants.module.scss" as styles;

.stack {
  padding-top: 21px;
  display: block !important;
}

.settings {
  display: flex;
  .item:not(:last-child) {
    margin-right: 20px;
  }
  .item {
    flex: 1;
    height: 173px;
    min-height: 173px;
    display: flex;
    flex-direction: column;

    .description {
      font-size: 14px;
      flex: 1;
    }

    .action {
      display: flex;
      flex-direction: column;

      .link {
        font-size: 15px;
        font-weight: 600;
        text-decoration: none;
        margin-top: 6px;
        margin-bottom: 15px;
      }

      button {
        width: 123px;
      }
    }
  }
}

.title {
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  margin-top: 21px;
  margin-bottom: 15px;
}