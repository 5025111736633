html, body, #root, #root>div, #root>p {
    height: 100%
}

body {
    margin: 0px;
    font-size: 14px;
}

Button {
    text-transform: capitalize !important;
    font-size: 14px  !important;
    font-weight: 600  !important;
}

.title {
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
}

.subTitle {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #D1D1D1;
}