@use "../../../styles/constants.module.scss" as styles;

.card {
    width: 231px;
    padding: 17px 21px;
    border-radius: 18px;
}

.header {
    margin-bottom: 33px;

    .headerTitle {
        display: flex;
        justify-content: space-between;
    }

    .headerIcon {
        width: fit-content
    }
}

.title {
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
}

.description {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
}

.price {
    font-weight: 600;
    font-size: 32px;
    line-height: 48px;
}

.period {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    position: relative;
    bottom: 9px;
}

.deactiveCard {
    border: 1px solid styles.$disabled-color;
    color: styles.$base-color;
}

.activeCard {
    background-color: styles.$base-color;
    border: 1px solid styles.$base-color;
    color: white;
}

.actionBtn {
    width: 100%;
    height: 44px;
}

.activeCard button {
    border: 1px solid white;
    color: white;
}

.deactiveCard button {
    border: 1px solid styles.$base-color;
    color: styles.$base-color;
}

.optionList {
    margin-top: 24px;
}

.optionItem {
    display: flex;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    align-items: center;
    height: 25.5px;
}

.optionItem .optionIcon {
    width: 14px;
    margin-right: 17px;
}