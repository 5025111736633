.soeContainer {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;

    .title {
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        margin-bottom: 9px;
    }

    .tableWrapper {
        max-width: 100%;
        overflow: auto;
    }
}