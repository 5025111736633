@use "../../../styles/constants.module.scss" as styles;

.stack > div {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.stack > div:not(:last-child) {
  height: 95px;
}

.stack > div:last-child {
  height: 115px;
}

.infoStyle {
  font-size: 15px;
  font-weight: 600;
  line-height: 22.5px;
}

.detailsStyle {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: styles.$disabled-color;
}

.actionArea {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
}

.actionArea button:not(:last-child) {
  margin-right: 18px;
}

.actionArea button{
  width: 80px;
}

.caption {
  width: 300px;
}

.value {
  display: flex;
  flex: 1;
  justify-content: flex-start;
  align-items: center;
  height: 95px;
}

.lastValue {
  display: flex;
  flex: 1;
  justify-content: flex-start;
  height: 115px;
}

.value .inputRoot {
  margin-right: 10px;
  height: 38px;
  
}

.value .inputRoot:last-child {
  flex: 1;
}

@media (min-width:1200px) {
  .value .inputRoot:last-child {
    margin-right: 215px;
  }
}

.value .inputElement {
  height: 18px;
  padding: 10px 12px;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
}

.avatar {
  margin-right: 20px;
}