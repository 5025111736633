@use "../../styles/constants.module" as styles;

.progressUploadCompoent {
  height: 90px;
  padding:  10px 25px 12px 21px;
  border: 1px solid #D1D1D1;
  border-radius: 3px;
}

.fileSize {
  color: styles.$disabled-color
}

.icon {
  color: styles.$base-color;
  float: right;
}