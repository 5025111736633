.root {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.caption {
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  margin-top: 23px;
}

.details {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}

.detailsArea {
  display: flex;
  justify-content: space-between;
}

.button {
  width: 200px;
}