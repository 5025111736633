.root {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.tabItem {
  width: fit-content;
  border-radius: 3px !important;
}

.content {
  display: flex;
  flex-direction: column;
  flex: 1;
}