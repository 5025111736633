.ArchiveComponent {
    display: flex;
    flex-direction: column;
    flex: 1;
}
  
.title {
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 12.97px;
  }