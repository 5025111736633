.body {
  margin: 0px;
  background-repeat: no-repeat;
  background-size: cover;
  font-family: 'Poppins';
  font-style: normal;

  .mainWrapper {
    padding-left: 20px;
    padding-right: 20px;
  }

  .main {
    display: flex;
    max-width: 1420px;
    margin: auto;
    min-height: 100vh;
  }

  .main .info {
    flex: 1;
  }

  .main .form {
    flex: 1;
  }

  .main .form {
    margin-top: 28.17px;
    margin-bottom: 74.67px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .main .form .contact {
    color: #3C3C3C;
    font-size: 12px;
  }

  .main .form .contact img {
    margin-right: 5px;
    position: relative;
    top: 3px;
  }

  .info {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 63px;
    padding-left: 31px;
    color: white;
  }

  .infoFooter {
    display: flex;
  }

  .infoFooter div {
    flex: 1;
  }

  .infoFooter div:first-child {
    flex: 1.5;
  }

  .info .scenario {
    margin-bottom: 2px;
  }

  .info .combines {
    margin-bottom: 10px;
    font-weight: 600;
    font-size: 15px;
  }

  .info .combines img {
    margin-left: 13px;
    position: relative;
    top: 4px;
  }

  input {
    width: 256px;
    height: 14px;
    padding: 10px;
    font-size: 12px;
    margin-top: 6px;
  }

  label {
    visibility: hidden;
  }

  label::after {
    display: block;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    visibility: visible;
  }

  button {
    width: 280px;
    height: 38px;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: #FFFFFF;
    background: #2497ED;
    border-radius: 3px;
    border: none;
  }

  .background {
    height: 100vh;
    width: 100vw;
    display: flex;
    position: fixed;
    z-index: -1;
  }

  .white {
    flex: 1;
    background-color: white;
  }

  .blue {
    flex: 1;
    background-color: #2497ED;
  }

  .image {
    background-image: url("https://simdecstorage.blob.core.windows.net/login-page/img.svg");
    height: 100vh;
    width: 100vw;
    position: absolute;
    background-repeat: no-repeat;
    background-position-x: right;
    background-size: 60%;
  }

  @media (min-height: 645px) and (max-height: 740px) {
    .image {
      background-size: 55%;
    }
  }

  @media (min-height: 622px) and (max-height: 645px) {
    .image {
      background-size: 53%;
    }
  }


  @media (min-height: 500px) and (max-height: 622px) {
    .image {
      background-size: 50%;
    }
  }
}