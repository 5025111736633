@use "../../styles/constants.module";
.UserInfo {
  display: flex;
  flex-direction: row;
  flex: 1;
  padding: 5px;
}

.UserInfoSmall {
  display: flex;
  flex-direction: row;
  flex: 1;
  padding: 5px;
}

.mail {
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  color: constants.$disabled-color;
  width : 160px;
  padding-left: 10px;
}

.mailSmall {
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  color: constants.$disabled-color;
}

.name {
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  width : 160px;
  padding-left: 10px;
}

.nameSmall {
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
}

.avatar {
  width: 40px;
  height: 40px;
}

.userDescription {
  margin-left: 10px;
}

.avatarSmall {
  margin-right: 6px;
  width: 15px;
  height: 15px;
}

.cutText {
  display: inline-block;
  overflow:hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
