@use "../../styles/constants.module" as styles;

.Results {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-bottom: 20px;
}

.header {
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
}

.fileName {
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
    margin-top: 12px;
}

.fileName .name {
    font-weight: 400;
    color: styles.$base-color;
}

.additionalInformation {
    display: flex;
    justify-content: space-between;
    color: styles.$disabled-color;
    font-size: 15px;
    margin-top: 7px
}
.additionalInformation .time {
    display: inline-block;
    margin-left: 17px;
}

.FieldsArea {
    margin-bottom: 20px;
}

.ActionArea {
    margin-bottom: 17px;
    display: flex;
    // justify-content: flex-end;
}

.ActionArea .grid {
    min-width: 39px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.ActionArea .grid img {
    width: 14px;
}

.ActionArea .chartView {
    display: flex;
    padding-right: 14px;
    padding-left: 14px;
}

.ActionArea .chartView button {
    height: 44px;
    border-radius: 3px;
    padding: 7px 12px;
}

.ActionArea .menu {
    display: flex;
    padding-right: 16px;
    flex: 1;
}

.ActionArea .menu button {
    height: 44px;
    width: 234px;
    border-radius: 3px;
    padding: 7px 12px;
    justify-content: start;
    color: white;
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
}

.ActionArea .menu button img {
    width: 24px;
    height: 24px;
    margin-right: 9px;
}

.ActionArea .menu button:last-child:hover {
    -webkit-box-shadow: 2px 2px 4px 0px rgba(242,201,76,0.64);
    -moz-box-shadow: 2px 2px 4px 0px rgba(242,201,76,0.64);
    box-shadow: 2px 2px 4px 0px rgba(242,201,76,0.64);
}

.ActionArea .menu button:first-child:hover {
    -webkit-box-shadow: 2px 2px 4px 0px rgba(39,174,96,1);
    -moz-box-shadow: 2px 2px 4px 0px rgba(39,174,96,1);
    box-shadow: 2px 2px 4px 0px rgba(39,174,96,1);
}

.ActionArea .menu button:first-child {
    margin-right: 15px;
}

.ActionArea .buttons {
    display: flex;
    width: 279px;
    justify-content: space-between;
}

.ActionArea .buttons button:not(:first-child) {
    margin-left: 5px;
}

.ActionArea .buttons button {
    width: 112px;
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 21px;
    padding: 8px 12px;
}

.divider {
    margin-bottom: 20px !important;
}

.gridIcon {
    height: 17px !important;
    cursor: pointer;
}

.disabledMenuItem {
    background-color: styles.$disabled-color !important;
}

@media (min-width:1100px) and (max-width:1140px) {

    .ActionArea .menu {
        padding-left: 10px;
    }
}

@media (min-width:1100px) and (max-width:1200px) {

    .ActionArea .buttons {
        width: 249px;
    }
}

@media (min-width:0px) and (max-width:1099px) {

    .ActionArea .buttons {
        width: 221px;
    }
}

@media (min-width:0px) and (max-width:1180px) {

    .ActionArea .menu  {
        padding-left: 10px;
    }

    .ActionArea .menu button {
        width: fit-content;
        justify-content: center;
    }

    .ActionArea .menu button .buttonName {
        display: none;
    }

    .ActionArea .menu button img {
        margin: 0px;
    }
}
