@use "../../styles/constants.module" as styles;

.ChipListComponent {
  margin-top: 17px;
}

.label {
  height: 42px;
  background-color: styles.$base-light-color;
  color: styles.$base-color;
  font-weight: 600;
  font-size: 15px;
  align-items: center;
  padding-right: 18px;
  padding-left: 18px;
  display: flex;
  justify-content: center;
}