@use "../../styles/constants.module" as styles;

.ChartViewToggle {
    background-color: styles.$disabled-color;

    .MuiButtonBase-root.MuiToggleButton-root.Mui-selected
    {
        background-color: styles.$base-color;
    }

    .MuiButtonBase-root.MuiToggleButton-root.Mui-disabled
    {
        background-color: styles.$disabled-color
    }
    
    button
    {
        width: 39px;
    }
    
    .white {
        filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%);
    }

    .gray {
        filter: brightness(0) saturate(100%) invert(55%) sepia(6%) saturate(10%) hue-rotate(33deg) brightness(91%) contrast(88%);
    }


}
