﻿$base-color: #2497ED;
$warning-color: #F2C94C;
$error-color: #EB5757;
$base-light-color: #EAF5FE;
$disabled-color: #D1D1D1;
$base-color-bg: rgba(36, 151, 237, 0.1);
$warning-color-bg: rgba(242, 201, 76, 0.3);
$error-color-bg: rgba(235, 87, 87, 0.3);

:export {
  colorPrimary : $base-color;
  colorSecondary : $base-light-color;
}