@use "../../styles/constants.module" as styles;

.UploadFileComponent {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  align-items: center;
  background: styles.$base-light-color;
  border-style: dashed;
  border-width: thin;
  border-color: styles.$base-color;
  border-radius: 3px;
  font-size: 14px;
  cursor: pointer;
}

.disabled {
  background: styles.$disabled-color;
}

.hidden {
  display: none;
}

.blob {
  font-weight: 600;
  display: contents;
}