@use "../../styles/constants.module" as styles;

.ArchiveTableFilter {
    display: inline-flex;
}

.item:hover,
.selected {
    color: styles.$base-color !important;
    background-color: styles.$base-light-color !important
}

.input {
    flex-direction: row-reverse;
    font-size: 14px;
    padding: 3px 10px;    
    width: 112px;
    height: 27px;
    margin-left: 18px;
    padding-left: 6px;
}

.notchedOutlineOpen {
    border-color: styles.$base-color !important;
    -webkit-text-fill-color: styles.$base-color !important;
    font-size: 14px !important;
    font-family: 'Poppins' !important;
    font-weight: 400 !important;
}

.innerInputOpen {
    color: styles.$base-color !important;
    -webkit-text-fill-color: styles.$base-color !important;
    font-size: 14px !important;
    font-family: 'Poppins' !important;
    font-weight: 400 !important;
}

.innerInputClose {
    font-size: 14px !important;
    font-family: 'Poppins' !important;
    font-weight: 400 !important;
}

.openIcon {    
    font-size: 18px;
    color: styles.$base-color;
}

.closeIcon {    
    font-size: 18px;
    color: styles.$disabled-color;
}