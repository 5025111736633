@use "../../../styles/constants.module.scss" as styles;

.card {
    width: 231px;
    padding: 17px 21px;
    border-radius: 18px;
}

.header {
    margin-bottom: 33px;

    .headerTitle {
        display: flex;
        justify-content: flex-end;
    }
}

.description {
    margin-top: 60px;
    .title {
        margin-bottom: 30px;
        font-size: 19px;
    }
}

.activeCard {
    background-color: styles.$base-color;
    border: 1px solid styles.$base-color;
    color: white;
}