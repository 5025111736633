.decompHeaderTitle {
    position: relative;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
}
    
    .decompHeaderTitle button {
        position: absolute;
        right: 5px;
        cursor: pointer;    
    }

    .decompContainer {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(214px, 1fr));
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
    }
    
    .decompContainerHeader, .decompContainerContent {
        display: flex;
    }
    
    .decompContainerHeader div,
    .decompContainerContent div {
        flex-grow: 1;
        width: 33%;
    }
    
    .decompItemContainer{
        flex-grow: 1;
    }
    
    .decompContainerHeader div {
        text-align: center;
    }
    
    .inputContainer,
    .inputContainer input {
        text-align: right;
    }
    
    .inputContainer input,
    .decompContainerContent input{
        width: 55px;
    }
    
    .decompContainerContent,
    .decompContainerSwitch,
    .decompContainerHeader {
        height: 25px;
        position: relative;
        padding-right: 20px;
    }
    
    .decompContainerSwitch select {
        width: 90%;
    }
    
    .decompContainerSwitch .button,
    .decompContainerContent .button {
        color: red;
        cursor: pointer;
        position: absolute;
        right: 5px;
        width: 25px;
    }
    
    .catValue {
    float: right;
    }
    
    .decompItemContainer {
    padding: 0px 2px;
    }