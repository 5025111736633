.colorButton {
    width: 39px;
    height: 18px;
}

.scenariosContainer {

    table {
        border-spacing: 1px;
    }

    .title {
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        margin-bottom: 26px;
        display: flex;
        justify-content: space-between;
        img {
            cursor: pointer;
        }
    }

    th {
        font-weight: 400;
        font-size: 10px;
        line-height: 15px;
    }

    th {
        border: 1px solid black;
        text-align: center;
        div {
            display: inline-block;
        }
    }
}

.tableWrapper {
    overflow: auto;
    max-width: 100%;
}

.SketchPicker {
    margin-top: 5px;
    width: 220px !important;
    padding-right: 35px !important;
}

.pickerWrapper {
    position: relative;
    width: fit-content;
}

.closeIcon {
    position: absolute;
    top: 11px;
    right: 5px;
    cursor: pointer;
}