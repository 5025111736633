@use "../../../../styles/constants.module.scss" as styles;

.alertLayout {
    position: fixed;
    top: 0px;
    left: 0px;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    z-index: 9999;
}

.content {
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: white;
    width: 50%;
    min-width: 435px;    
    border-radius: 6px;
}

.title {
    display: flex;
    justify-content: space-between;
    padding: 11px 23px;
    font-size: 15px;
    font-weight: 600;
    border-radius: 6px 6px 0px 0px;
    color: white;
    background-color: styles.$base-color;

    .text {
        flex: 1;
    }

    .closeIcon {
        width: 24px;
        cursor: pointer;
    }
}

.description {
    padding: 23px 24px;
    border-radius: 0px 0px 6px 6px;
    font-size: 14px;
    font-weight: 400;
}

.alertError {
    .title {
        background-color: styles.$error-color;
    }
}

.alertInfo {
    .title {
        background-color: styles.$base-color;
    }
}

.alertWarning {
    .title {
        background-color: styles.$warning-color;
    }
}

.actionArea {
    display: flex;
    justify-content: end;

    .actionButton:not(:last-child) {
        margin-right: 13px;
    }
}

.progress {
    position: absolute;
    top: 50%;
    left: 50%;
}