@use "../../../styles/constants.module.scss" as styles;

.planArea {
  display: grid;
  grid-gap: 20px;
  gap: 20px;
  grid-template-columns: repeat(auto-fit, minmax(275px, 1fr));
  justify-content: start; 
}

.PlansAndPricing {
  .transactionsPropgress {
    margin-bottom: 35px;
  }
}

.header {
  padding-top: 20px;
  padding-bottom: 4px;
  margin-bottom: 30px;
}

.PlansAndPricing {
  width: 100%;
  display: flex;
  flex-direction: column;
}