@use "../../styles/constants.module" as styles;

.limitationCard {
    background-color: styles.$base-color-bg;
    padding: 15px;
    border-radius: 3px;
    font-size: 12px;

    .limitationItem:not(:last-child) {
        margin-bottom: 20px;
    }
}

.limitationCard.error {
  background-color: styles.$error-color-bg;
}

.limitationCard.warning {
  background-color: styles.$warning-color-bg;
}

.colorPrimary {
  background-color: styles.$base-color-bg !important;
}

.barColorPrimary {
  background-color: styles.$base-color !important;
}

.limitationItem.warning {

  .colorPrimary {
    background-color: "white";
  }
  
  .barColorPrimary {
    background-color: styles.$warning-color !important;
  }
}

.limitationItem.error {

  .colorPrimary {
    background-color: "white";
  }
  
  .barColorPrimary {
    background-color: styles.$error-color !important;
  }
}

.loaderWrapper {
  width: 100%;
  text-align: center;
}

.limitationItem {

  .title {
      margin-bottom: 7px;
  }
  
  .description {
      margin-bottom: 15px;
      white-space: break-spaces;
  }
}

.footer {
  display: flex;
  justify-content: space-between;

  .link {
    font-weight: 900;
    color: black;
    text-decoration: none;
  }
}

.limitationCardClose {
  .limitationCardCloseItem:not(:last-child) {
    margin-bottom: 13px;
  }

  .limitationCardCloseItem {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 7.5px 0px;
    border-radius: 3px;
    img {
      filter: invert(49%) sepia(26%) saturate(4472%) hue-rotate(183deg) brightness(100%) contrast(87%);
    }
  }

  .limitationCardCloseItemLoader {
    background-color: styles.$base-color-bg;
  }

  .limitationCardCloseItem:hover {
    background-color: styles.$base-color-bg;
  }
  
  .limitationCardCloseItem.warning {
    background-color: styles.$warning-color-bg;
    img {
      filter: invert(80%) sepia(63%) saturate(476%) hue-rotate(345deg) brightness(96%) contrast(97%);
    }
  }
  
  .limitationCardCloseItem.warning:hover {
    background-color: styles.$warning-color-bg;
  }
  
  .limitationCardCloseItem.error {
    background-color: styles.$error-color-bg;
    img {
      filter: invert(35%) sepia(45%) saturate(1177%) hue-rotate(319deg) brightness(119%) contrast(86%);
    }
  }
  
  .limitationCardCloseItem.error:hover {
    background-color: styles.$error-color-bg;
  }
}

