@use "../../styles/constants.module" as styles;


.root {
  max-width: 880px;
  height: 303.55px;
  display: flex;
  border-radius: 6px;
  background-color: styles.$base-color-bg;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: styles.$base-color;
  font-size: 20px;
  font-weight: 600;
}

.root img {
  margin-bottom: 24px;
}

.root Button {
  margin-top: 12px;
}