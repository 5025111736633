
.logo {
  width: 65.56px;
  height: 51.15px;
}

.content {
  flex: 1;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 28px;
  border-left: 1px solid #D1D1D1;
  height: fit-content;
  min-height: 100%;
}

.main {
  max-width: 1200px;
  display: flex;
  margin: auto;
  height: 100%;
  min-height: 100%;
}

.menu {
  margin-top: 53.48px;
}

.drawer {
  display: flex;
  flex: 1;
}

.logoArea {
  display: flex;
  align-items: center;
  margin-top: 15px;
  img:first-child {
    width: 50%;
  }
  img:last-child {
    height: 32px;
    margin-left: 10px;
  }
}