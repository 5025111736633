@use "../../styles/constants.module";
.SignOut {
  display: flex;
  flex: 1;
  justify-content: space-between;
}

.SignOut:hover {
  background-color: constants.$base-light-color;
}

.img {
  height: 16px;
  padding-right: 10px;
  padding-top: 10px;
}