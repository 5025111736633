
    .significanceContainer {
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        margin-bottom: 23px;
        
        .title {
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            margin-bottom: 25px;
        }

        table {
            width: 96%;
        }
    }

    .signPercent {
        text-align: right;
    }