@use "../../../styles/constants.module.scss" as styles;

.transactionsPropgress {

  .colorPrimary {
    background-color: styles.$disabled-color !important;
  }
  
  .barColorPrimary {
    background-color: styles.$base-color !important;
  }
  
  .transactionCount {
      position: absolute;  
      top: 4px;
  }
  
  .number { //part of style for this element set as inline it is need for flex position of element
    background-color: #D9D9D9 !important;
    height: 25px;
    display: flex;
    justify-content: center;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    align-items: center;
    border-radius: 4px;
    color: white;
  }
  
  .circle {
    width: 19px;
    height: 19px;
    border-radius: 50%;
    background-color: styles.$base-color;
    display: flex;    
    position: absolute;
    bottom: -7px;
    z-index: 1;
  }
  
  .circle div {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: white;
    margin: auto;
  }
  
  .progressBarr {
    position: relative;
    padding-top: 21px;
  }
  
  .rectangle {
    left: calc(50% - 5.5px);
    background-color: #D9D9D9;
    width: 11px;
    height: 11px;
    position: absolute;
    transform: rotate(45deg);
    top: 19px;
  }
  
  .title {
    display: flex;
    justify-content: space-between;
    font-size: 15px;
  
    .name {
      font-weight: 600;
    }
  
    .limitation {
      color: styles.$base-color;
      span:first-child {
        font-weight: 600;
      }
    }
  }
}

.transactionsPropgress.error {

  .circle {
    background-color: styles.$error-color;
  }
  
  .barColorPrimary {
    background-color: styles.$error-color !important;
  }

  .limitation {
    color: styles.$error-color;
  }
}

.transactionsPropgress.warning {

  .circle {
    background-color: styles.$warning-color;
  }
  
  .barColorPrimary {
    background-color: styles.$warning-color !important;
  }

  .limitation {
    color: styles.$warning-color;
  }
}