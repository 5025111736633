@use "../../styles/constants.module" as styles;

.linkActive,
.linkNotActive:hover {
  background-color: styles.$base-color-bg;
  color: styles.$base-color;
}

.linkNotActive {
  color: black;
}

.link:visited,
.link {
  padding: 9px 14.2px;
  display: flex;
  border-radius: 3px;
  text-decoration: none;
  height: 24px;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  span {
    flex: 1;
  }
  img {
    width: 14px;
  }
}

.linkName {
  margin-left: 10px;
}