@use "../../styles/constants.module.scss" as styles;

.chartItem {
    border: 1px solid styles.$disabled-color;
    border-radius: 3px;
    padding: 17px;
}

.ChartContainer {
    display: grid;   
    grid-template-columns: repeat(2, minmax(0, 0.5fr));
    gap: 15px;

}